@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}
.nav_header {
  display: flex;
  justify-content: space-between;
  background-color: black;
  padding: 2rem 0.25rem;
  align-items: center;
  font-family: "Poppins", sans-serif;
  .nav_brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      display: none;
      width: 25px;
      height: 25px;
      color: rgb(223, 223, 223);
      cursor: pointer;
    }
  }
  img {
    max-width: 80.7px;
    height: 80.7px;
  }
  .header_menu {
    list-style: none;
    display: flex;
    transition: all 1s;
    li {
      padding-inline: 1rem;
      font-size: 14px;
      font-weight: 300;
      a {
        text-decoration: none;
        color: #888;
        &.active_menu {
          color: #fff;
        }
        &:hover {
          color: #fff;
        }
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media (max-width: 679px) {
    .header_menu {
      display: none;
    }
    &.toggle_mode {
      flex-direction: column;
      .header_menu {
        display: block;
        padding-left: 0;
        margin: 1rem;
        width: calc(100% - 2rem);
        li {
          width: 100%;
          text-align: center;
          background: #131313;
          padding: 10px 0;
          margin-bottom: 0.5rem;
        }
      }
    }
    .nav_brand {
      width: 100%;
      padding-inline: 1rem;
      svg {
        display: block;
      }
    }
  }
}
