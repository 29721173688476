.home_page {
  background-color: black;
  padding-bottom: 2rem;
  font-family: "Ubuntu", sans-serif;
  .home_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 2rem;
    grid-gap: 20px;
    .img_wrapper {
      position: relative;
      max-width: 100%;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      overflow: hidden;
      img {
        width: 100%;
        height: 250px;
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        &:hover {
          filter: none;
          -webkit-filter: none;
        }
      }
      .overflow_wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #00000069;
        width: 100%;
        h4 {
          color: #fff;
          position: absolute;
          bottom: 20px;
          left: 20px;
          font-weight: 700;
          opacity: 0;
          text-shadow: 2px 2px #00000030;
        }
      }
      .overflow2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0);
      }
      &:hover {
        transform: scale(1.1);
        img {
          filter: none;
          -webkit-filter: none;
        }
        .overflow_wrapper {
          background: transparent;
          h4 {
            opacity: 1;
          }
        }
        .overflow2 {
          animation: expandImage 0.3s;
        }
      }
    }
    @media (max-width: 979px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 779px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 579px) {
      grid-template-columns: 1fr;
      .img_wrapper {
        img {
          height: auto;
        }
      }
    }
  }
  .scroll_area {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    button {
      background: none;
      color: #888;
      outline: none;
      padding: 8px 1rem;
      &:hover {
        color: #fff;
      }
    }
  }
  @keyframes expandImage {
    from {
      top: 50%;
      left: 50%;
      background: #00000069;
      width: 30px;
      height: 30px;
      opacity: 0.05;
      transform: translate(-50%, -50%);
    }
    to {
      width: 100%;
      height: 100%;
      transform: none;
      top: 0;
      left: 0;
      opacity: 1;
    }
  }
}
